<!--
 * @Description: 商品菜单
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-02 21:08:07
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '咨询反馈',
          list: [
            {
              name: '咨询反馈',
              path: '/mall/message/sale-feedback'
            }
          ]
        }
      ]
    }
  }
}
</script>
